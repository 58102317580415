import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { StorySelector } from "../story-selector/story-selector";

export const StorySelectorModal = ({
  show,
  onHide,
  onStorySelect,
  selectedStory,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName="select-fotostory-modal"
      size={"lg"}
    >
      <div className="modal-header">
        <h2 className="modal-title">Fotostories</h2>
        <div className="pull-right">
          <div className="home">
            <a href="https://supa.lol/" className="button-home">
              Home
            </a>
          </div>
          <button className="modal-close-button" onClick={onHide}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      <div className="modal-body">
        <StorySelector
          onStorySelect={onStorySelect}
          selectedStory={selectedStory}
        />
      </div>
    </Modal>
  );
};
