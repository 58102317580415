import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ded8d17d90b9f00b864e2b876579d8c6@o4506072308056064.ingest.sentry.io/4506072309497856",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const instance = createInstance({
  urlBase: "https://matomo.sebastianstampfel.live/",
  siteId: 2,
  //userId: "UID76903202", // optional, default value: `undefined`.
  // trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
  // srcUrl: "https://LINK.TO.DOMAIN/tracking.js", // optional, default value: `${urlBase}matomo.js`
  // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  // heartBeat: {
  // optional, enabled by default
  // active: true, // optional, default value: true
  // seconds: 10, // optional, default value: `15
  // },
  // linkTracking: false, // optional, default value: true
  // configurations: {
  // optional, default value: {}
  // any valid matomo configuration, all below are optional
  // disableCookies: true,
  // setSecureCookie: true,
  // setRequestMethod: "POST",
  // },
});

root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
