import React, { useEffect, useCallback } from "react";

export const usePageNavigation = ({
  arrowKeysEnabled,
  onNextPage,
  onPrevPage,
}) => {
  const handler = useCallback(
    (e) => {
      if (!arrowKeysEnabled) return;
      switch (e.key) {
        case "ArrowRight":
          onNextPage?.();
          break;
        case "ArrowLeft":
          onPrevPage?.();
          break;
        default:
          break;
      }
    },
    [arrowKeysEnabled, onNextPage, onPrevPage]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => window.removeEventListener("keydown", handler, false);
  }, [handler]);

  return {
    hello: 1,
  };
};
