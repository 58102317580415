import React, { useState, useEffect } from "react";

export const useFullscreenControls = ({ viewer }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => setIsFullscreen((old) => !old);

  useEffect(() => {
    if (!viewer) return;

    const handler = ({ fullScreen }) => {
      setIsFullscreen(fullScreen);
    };

    viewer.addHandler("full-screen", handler);

    return () => {
      viewer.removeHandler("full-screen", handler);
    }
  }, [viewer]);

  useEffect(() => {
    if (!viewer) return;

    if (viewer.isFullScreen !== isFullscreen) {
      viewer.setFullScreen(isFullscreen);
    }
  }, [viewer, isFullscreen]);
  return { isFullscreen, toggleFullscreen };
};
