import React, { useState, useEffect } from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buildClassName } from "../utils";
import { STORIES } from "../stories";

import "./styles.scss";

export const NowReading = ({ story }) => {
  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setVisible(true);
    setHidden(false);
  
    setTimeout(() => setVisible(false), 3000);
    setTimeout(() => setHidden(true), 3500);
  }, [story]);

  return (
    <div
      className={buildClassName(
        "now-reading",
        !visible && "o-0",
        hidden && "d-none"
      )}
    >
      <div className="info">
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
      <div className="thumb">
        <img src={STORIES[story].image} />
      </div>
      <div className="meta">
        <h5>Du liest</h5>
        <h3>{STORIES[story].title}</h3>
        <h4>{STORIES[story].year}</h4>
      </div>
    </div>
  );
};
