import React from "react";
import { buildClassName } from "../utils";
import { STORIES } from "../stories";
import "./styles.scss";

export const StorySelector = ({ onStorySelect, selectedStory }) => {
  return (
    <div className="story-selector">
      <div className="container">
        {Object.keys(STORIES).reverse().map((storyKey) => {
          const story = { ...STORIES[storyKey], id: storyKey };
          return (
            <div
              className={buildClassName(
                "story-container",
                selectedStory === story.id && "active"
              )}
              onClick={() => onStorySelect?.({ story: story.id })}
            >
              <img src={story.image} />
              <h3>{story.title}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};
