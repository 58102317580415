import OpenSeaDragon from "openseadragon";
import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faHouse,
  faExpand,
  faFile,
  faBars,
  faExpandArrowsAlt,
  faChevronLeft,
  faChevronRight,
  faShare,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./logo.png";
import "./styles.scss";
import { useZoomControls } from "./use-zoom-controls";
import { useFullscreenControls } from "./use-fullscreen-controls";
import { useHomeControl } from "./use-home-control";
import { usePageNavigation } from "./use-page-navigation";
import background from "../images/reader-bg-02.svg";
import PuffLoader from "react-spinners/PuffLoader";
import { buildClassName } from "../utils";

const dziToTilesource = ({ xmlDzi, filesUrl }) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlDzi, "text/xml");

  const dzi = {
    Image: {
      xmlns: xml.querySelector("Image").getAttribute("xmlns"),
      Url: filesUrl,
      Format: xml.querySelector("Image").getAttribute("Format"),
      Overlap: xml.querySelector("Image").getAttribute("Overlap"),
      TileSize: xml.querySelector("Image").getAttribute("TileSize"),
      Size: {
        Height: xml.querySelector("Size").getAttribute("Height"),
        Width: xml.querySelector("Size").getAttribute("Width"),
      },
    },
  };

  return dzi;
};

const ShareButton = ({ mobile, isBusy, onClick }) => {
  return (
    <button
      className={buildClassName("next-page share", isBusy && "share-busy")}
      onClick={!isBusy ? onClick : () => null}
    >
      {!isBusy ? (
        <FontAwesomeIcon icon={faShareAlt} />
      ) : (
        <PuffLoader
          color="#ffffff"
          size={mobile ? 60 : 30}
          cssOverride={{ display: "block" }}
        />
      )}
    </button>
  );
};

export const OpenSeaDragonViewer = ({
  image,
  url,
  children,
  onPrevPage,
  onNextPage,
  onStoriesClick,
  backgroundImage = true,
  arrowKeysEnabled = true,
  maxPage,
  currPage,
  onShareClick,
  isShareBusy,
}) => {
  const [viewer, setViewer] = useState(null);
  const viewerRef = useRef();
  const { zoom, zoomIn, zoomOut, setZoom } = useZoomControls({
    viewer,
    zoomFactor: 0.25,
  });
  const { toggleFullscreen } = useFullscreenControls({ viewer });
  const { goHome } = useHomeControl({ viewer });

  usePageNavigation({
    arrowKeysEnabled,
    onNextPage,
    onPrevPage,
  });

  useEffect(() => {
    fetch(image).then((res) => {
      res.text().then((text) => {
        // viewer.open()
        const source = dziToTilesource({ xmlDzi: text, filesUrl: url });
        if (viewer) {
          viewer.open(source);
        }
      });
    });
  }, [image, url, viewer]);

  useLayoutEffect(() => {
    // eslint-disable-next-line
    viewer && viewer.destroy();
    // eslint-disable-next-line
    viewer && viewer.close();

    const _viewer = OpenSeaDragon({
      // id: "openSeaDragon",
      element: viewerRef.current,
      prefixUrl: "openseadragon-images/",
      animationTime: 0.6,
      blendTime: 0.05,
      constrainDuringPan: false,
      panHorizontal: true,
      panVertical: true,
      maxZoomPixelRatio: 2,
      minZoomLevel: 0,
      defaultZoomLevel: 0,
      visibilityRatio: 0.05,
      // zoomPerScroll: 2,
      zoomPerClick: 1,
      showZoomControl: false,
      showFullPageControl: false,
      showRotationControl: false,
      showFlipControl: false,
      showHomeControl: false,
    });
    setViewer(_viewer);

    return () => {
      _viewer && _viewer.destroy();
      _viewer && _viewer.close();
      setViewer(null);
    };
    // eslint-disable-next-line
  }, []);

  const _onNextPage = useCallback(() => {
    const zoomOld = zoom;
    onNextPage?.();
    setTimeout(() => {
      viewer?.viewport?.zoomTo(zoomOld, null, true);
      setZoom(zoomOld);
    }, 50);
  }, [onNextPage, zoom, viewer]);

  const _onPrevPage = useCallback(() => {
    const zoomOld = zoom;
    onPrevPage?.();
    setTimeout(() => {
      viewer?.viewport?.zoomTo(zoomOld, null, true);
      setZoom(zoomOld);
    }, 50);
  }, [onPrevPage, zoom, viewer]);

  const progress = useMemo(() => {
    const progress = Math.floor((100 / maxPage) * currPage);
    console.log(progress);
    return Math.max(10, progress);
  }, [currPage, maxPage]);

  const shouldShareButtonShow = Boolean(navigator.canShare) && currPage === maxPage;

  return (
    <div style={{ height: "100%" }} className={"open-sea-dragon-wrapper"}>
      {backgroundImage && (
        <div className="viewer-background">
          <img src={background} />
        </div>
      )}
      <div className="viewer-utils">
        <div className="zoom-container">
          <button className="zoom-out" onClick={() => zoomIn()}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="zoom-out" onClick={() => zoomOut()}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
        {false && (
          <div className="misc-utils">
            <button className="home" onClick={() => goHome()}>
              <FontAwesomeIcon icon={faFile} />
            </button>
            <button className="home" onClick={() => toggleFullscreen()}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
          </div>
        )}
        <div className="page-controls">
          <button className="prev-page" onClick={_onPrevPage}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          {!shouldShareButtonShow ? (
            <button className="next-page" onClick={_onNextPage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          ) : (
            <ShareButton isBusy={isShareBusy} onClick={onShareClick} />
          )}
        </div>
        <div className="page-indicator-container">
          <div className="page-indicator">
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                borderRadius: progress > 85 ? "12px" : "12px 0 0 12px",
              }}
            ></div>
            <p>
              {currPage}/{maxPage}
            </p>
          </div>
        </div>
      </div>
      <div className="viewer-utils-mobile">
        <div className="page-indicator-container">
          <div className="page-indicator">
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                borderRadius: progress > 85 ? "12px" : "12px 0 0 12px",
              }}
            ></div>
            <p>
              {currPage}/{maxPage}
            </p>
          </div>
        </div>
      </div>
      <div className="viewer-utils-mobile-bottom">
        <div className="page-controls">
          <button className="prev-page" onClick={_onPrevPage}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          {!shouldShareButtonShow ? (
            <button className="next-page" onClick={_onNextPage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          ) : (
            <ShareButton isBusy={isShareBusy} onClick={onShareClick} mobile />
          )}
        </div>
      </div>
      <div className="viewer-utils-right">
        <div className="stories-button">
          <button className="show-stories" onClick={() => onStoriesClick?.()}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>
      <div className="watermark">
        <img src={logo} />
      </div>
      <div id="openSeaDragon" ref={viewerRef}></div>
    </div>
  );
};
