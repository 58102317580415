import * as Sentry from "@sentry/react";
import { useState } from "react";

export const useShare = ({ shareable, trackEvent, selectedStory }) => {
  const [isBusy, setIsBusy] = useState(false);

  const shareShareable = async () => {
    if (!shareable || isBusy) return;
    setIsBusy(true);
    const response = await fetch(shareable);
    const data = await response.blob();
    const metadata = {
      type: "image/png",
    };
    const file = new File([data], "share.png", metadata);
    try {
      await window.navigator.share({
        files: [file],
      });
      setIsBusy(false);
      trackEvent({
        name: "shared-story",
        category: "general",
        action: selectedStory,
      });
    } catch (error) {
      setIsBusy(false);
      const msg = `${error}`;
      if (msg.includes("AbortError")) return;
      Sentry.captureMessage(`Error while sharing: ${msg}`, "error");
    }
    return file;
  };
  return { shareShareable, isShareBusy: isBusy };
};
