import React, { useState, useCallback } from "react";
import { useEffect } from "react";

export const useZoomControls = ({ viewer, zoomFactor }) => {
  const [zoom, setZoom] = useState(0);

  const updateZoom = useCallback(
    ({ newZoom }) => {
      if (newZoom !== zoom) {
        setZoom(newZoom);
      }
    },
    [zoom]
  );

  useEffect(() => {
    if (!viewer) return;

    const handler = (e) => {
      updateZoom({ newZoom: e.zoom });
    };

    viewer.addHandler("zoom", handler);

    return () => {
      viewer.removeHandler("zoom", handler);
    };
  }, [viewer, updateZoom]);

  // const keepZoom = useCallback(() => {
  //   if (!viewer) return;

  //   viewer?.viewport?.zoomTo(zoom);
  // }, [viewer, zoom]);

  // useEffect(() => {
  //   if (!viewer) return;

  //   viewer.addHandler("open", keepZoom);

  //   return () => {
  //     viewer.removeHandler("open", keepZoom);
  //   };
  // }, [viewer, keepZoom]);

  useEffect(() => {
    if (!viewer || !viewer?.viewport) return;
    setZoom(viewer.viewport.getZoom());
  }, [viewer]);

  const zoomIn = useCallback(() => {
    setZoom((oldZoom) => oldZoom + zoomFactor);
  }, [zoomFactor]);

  const zoomOut = useCallback(() => {
    setZoom((oldZoom) => oldZoom - zoomFactor);
  }, [zoomFactor]);

  useEffect(() => {
    if (!viewer) return;

    if (viewer?.viewport?.getZoom() !== zoom) {
      viewer?.viewport?.zoomTo(zoom);
    }
  }, [zoom]);

  return { zoom, zoomIn, zoomOut, setZoom };
};
