import React, { useCallback } from "react";

export const useHomeControl = ({ viewer }) => {
  const goHome = useCallback(() => {
    if (!viewer) return;

    viewer.viewport.goHome();
  }, [viewer]);
  return { goHome };
};
